import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Os nossos serviços | Os nossos serviços na International Lawyers
			</title>
			<meta name={"description"} content={"Soluções jurídicas sob medida para cada necessidade"} />
			<meta property={"og:title"} content={"Os nossos serviços | Os nossos serviços na International Lawyers"} />
			<meta property={"og:description"} content={"Soluções jurídicas sob medida para cada necessidade"} />
			<meta property={"og:image"} content={"https://haltiner-best.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://haltiner-best.com/img/16406.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://haltiner-best.com/img/16406.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://haltiner-best.com/img/16406.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://haltiner-best.com/img/16406.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://haltiner-best.com/img/16406.png"} />
			<meta name={"msapplication-TileImage"} content={"https://haltiner-best.com/img/16406.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://haltiner-best.com/img/1.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="980px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2" text-align="center" >
					Visão geral dos nossos serviços jurídicos
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					A International Lawyers fornece uma gama abrangente de serviços jurídicos concebidos para satisfazer as diversas necessidades dos nossos clientes. As nossas áreas de prática são compostas por profissionais dedicados e especializados nas suas áreas, assegurando aconselhamento e representação especializados.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-8">
			<Text margin="0px 0px 35px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				As nossas principais áreas de serviço
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Direito Empresarial: Desde empresas em fase de arranque a empresas estabelecidas, a nossa equipa oferece orientação sobre a formação de empresas, contratos, fusões e aquisições, assegurando que a sua empresa é legalmente sólida.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Direito da Família: Tratamos de assuntos delicados como o divórcio, a custódia dos filhos e o planeamento imobiliário com empatia e profissionalismo, salvaguardando os seus interesses e os dos seus entes queridos.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Defesa Criminal: Os nossos advogados de defesa experientes estão empenhados em proteger os direitos dos indivíduos que enfrentam acusações criminais, proporcionando uma representação vigorosa em todas as fases do processo legal.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Direito Imobiliário e de Propriedade: Quer se trate de uma compra residencial ou de uma transação imobiliária comercial complexa, a nossa equipa navega pelos meandros do direito imobiliário para garantir os seus investimentos.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Direito do Trabalho: Ajudamos tanto empregadores como empregados a navegar nas complexidades dos contratos de trabalho, disputas no local de trabalho e cumprimento das leis laborais.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						- Propriedade intelectual: Os nossos especialistas em PI ajudam a proteger as suas inovações e trabalhos criativos, gerindo tudo, desde pedidos de patentes a litígios sobre direitos de autor.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});